.checkout {
	min-height: 90vh;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}

@media screen and (max-width: 1540px) {
	/* .checkout {
		height: 87vh;
		overflow-y: hidden;
	} */
}

.swiper-button-prev {
    color: red; 
}

.swiper-button-next {
    color: #000; 
}
