p,
h6 {
	font-family: Roboto-Medium !important;
	color: #04246a;
}

h1,
h2 {
	font-family: Antonio-Bold !important;
	color: #04246a;
}

h3,
h4,
h5,
a,
span {
	font-family: Roboto-Medium !important;
	color: #04246a;
}

button {
	font-family: Roboto-Bold !important;
	color: #04246a;
}
