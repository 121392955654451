.items {
	width: 100%;
	height: 97%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.items-table {
	width: 100%;
	/* height: 100%; */
	height: 90%;
	overflow-y: auto;
	background-color: #fff;
	display: flex !important;
	flex-direction: column;
	justify-content: space-between;
	box-shadow: 5px 5px 5px #dddedf;
}

.items-table::-webkit-scrollbar {
	width: 8px;
}

.items-table::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.items-table::-webkit-scrollbar-thumb {
	background-color: darkgrey;
}

.items-head {
	flex: 0.1;
	display: flex !important;
	align-items: center;
	justify-content: center;
	padding-left: 10px;
	padding-right: 10px;
	border-bottom: 1px solid lightgray;
}

.items-row {
	flex: 1;
	display: flex !important;
	align-items: center;
	justify-content: space-between;
}

.items-cell {
	flex: 0.15;
	font-size: 20px !important;
	color: #04246a !important;
	font-weight: 700 !important;
	display: flex !important;
	align-items: center;
	justify-content: flex-start;
	border-bottom: 0 !important;
	padding: 0px !important;
}

.items-cell:first-child {
	flex: 0.3;
}

.items-cell:nth-child(2) {
	flex: 0.25;
}

.items-body {
	flex: 0.9;
	margin-top: 10px;
	display: flex !important;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

.items-body-row {
	flex: 0.15;
	width: 100%;
	margin-top: 10px;
	margin-bottom: 10px;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid lightgray;
	display: flex !important;
	align-items: center;
	justify-content: space-between;
}

.items-body-row:first-child {
	margin-top: 0;
}

.items-body-cell {
	flex: 0.15;
	height: 100%;
	color: #04246a !important;
	font-weight: 500 !important;
	display: flex !important;
	align-items: center;
	justify-content: flex-start;
	border-bottom: 0 !important;
	padding: 0px !important;
}

.items-body-cell:first-child {
	flex: 0.3;
	display: flex;
	justify-content: space-between;
}

.items-body-cellLeft {
	flex: 0.2;
	height: 100%;
	display: flex;
	align-items: center;
}

.items-body-cellLeft img {
	width: 30px;
	height: 30px;
}

.items-body-cellRight {
	flex: 0.8;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}

.items-body-cellRight-row {
	flex: 0.2;
}

.items-body-cellRight-row:first-child {
	width: 100%;
}

.items-body-cellRight-row:nth-child(2) {
	width: 100%;
	color: lightgray;
}

.items-body-cellRight-button {
	border: none;
	background-color: transparent;
	padding: 0px !important;
	font-size: 14px !important;
	color: rgba(255, 0, 0, 0.647) !important;
	text-decoration: underline;
}

.items-body-cellRight-row:last-child {
	width: 100%;
}

.items-body-cell:nth-child(2) {
	flex: 0.25;
}

.items-body-text {
	width: 31%;
}

.items-shopping {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	padding-bottom: 10px;
}

.items-shopping-button {
	border: none;
	color: #04246a;
	font-size: 16px;
	background-color: transparent;
	text-decoration: underline;
}

@media screen and (max-width: 1540px) {
	.items {
		max-height: 100%;
	}

	.items-head {
		min-height: 10%;
	}

	.items-cell,
	.items-body-cell,
	.items-body-cellRight-button {
		font-size: 12px !important;
	}

	.items-cell:nth-child(2),
	.items-body-cell:nth-child(2) {
		flex: 0.3;
	}

	.items-cell:first-child,
	.items-body-cell:first-child {
		flex: 0.4;
	}
}
