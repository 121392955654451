.orderDetail-footer {
	margin-top: 15px;
	display: flex !important;
	width: 510px;
	padding: 0px 10px;
	position: absolute;
	bottom: 10px;
	right: 0px;
	margin-right: 70px;
}

.orderDetail-footer-right {
	width: 100%;
	display: flex !important;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.orderDetail-footer-left {
	width: 100%;
	padding-right: 10px;
	display: flex !important;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.orderDetail-footer-row {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.orderDetail-footer-row p {
	margin: 0;
	color: #04246a;
	font-size: 14px;
}

.orderDetail-footer-row p:first-child {
	font-weight: 600;
}
