.admin-title {
	/* background-color: lightblue; */
	flex: 0.05;
	font-size: 20px;
	font-weight: 600;
	color: #04246a;
}

/* Table */
.admin-table {
	flex: 0.95;
	width: 100% !important;
	display: flex !important;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	overflow: hidden;
}

/* Table head */
.admin-head {
	/* background-color: lightblue; */
	flex: 0.1;
	width: 100%;
	display: flex !important;
	flex-direction: column;
}

.adminManageUsers-top p {
	font-size: 22px;
	font-weight: 600;
	color: #04246a;
}

.admin-headTop-row {
	display: flex !important;
	justify-content: space-between;
	padding-bottom: 0;
	background-color: white;
	box-shadow: 5px 5px 5px #dddedf;
}

.admin-headTop-row-item {
	flex: 0.16;
	color: #04246a !important;
	font-weight: 600 !important;
	font-size: 15px !important;
	border-bottom: none !important;

	padding-top: 8px !important;
	padding-bottom: 0 !important;
}

.admin-headBottom-row {
	display: flex !important;
	justify-content: space-between;

	margin-bottom: 10px;
	background-color: white;
	box-shadow: 5px 5px 5px #dddedf;
}

.admin-headBottom-row td {
	color: #04246a !important;
}

.admin-headBottom-row-item {
	flex: 0.16;
	color: #04246a !important;
	font-weight: 500 !important;
	border-bottom: none !important;

	padding-top: 0 !important;
	padding-bottom: 8px !important;
}

.admin-headBottom-row-item input {
	border: 1px solid lightgray;
	/* background-color: lightgray; */
	border-radius: 4px;
	padding: 4px 5px;
}

.admin-headBottom-row-item:nth-child(4) input {
	border: none;
}

.admin-headBottom-row-item input::placeholder {
	color: #04246a;
	font-size: 14px;
	font-style: italic;
}

.admin-headBottom-date input {
	padding: 4px 5px;
}

/* Table body */
.admin-body {
	/* background-color: lightgray; */
	flex: 0.9;
	width: 100%;
	display: flex !important;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.admin-body-row {
	flex: 0.1;
	width: 100%;
	display: flex !important;
	justify-content: space-between;
	margin-top: 10px !important;
	background-color: white;
	box-shadow: 5px 5px 5px #dddedf;
}

.admin-body-row:first-child {
	margin-top: 0 !important;
}

.admin-body-row-item {
	flex: 0.16;
	border-bottom: none !important;
	color: #04246a !important;
	font-weight: 500 !important;
}

/* Table footer */
.admin-footer {
	align-self: flex-end;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: none !important;
}

.admin-pagination {
	border-bottom: none !important;
}

.admin-pagination p {
	margin-bottom: 0;
}
