.select-form {
    width: 90%;
}

.select-label {
    margin-top: -10px;
    font-style: italic;
}

.select-input {
    padding: 0px;
	height: 35px;
    margin-bottom: 2px;
    border-radius: 0px;
}

.select-item {
    flex: 0.5;
}

@media screen and (max-width: 1540px) {
    .select-form {
        width: 100%;
    }
}