.footer {
	width: 100%;
	list-style: none;
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
}
.footer-middle {
	height: 100%;
	list-style: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.footer-right {
	list-style: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}
.footer-right p {
	font-size: 14px;
	font-weight: 300;
	font-style: italic;
}
.footer-right-list {
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.footer-item {
	padding: 5px 0;
}
.footer-item-link {
	background-color: transparent !important;
	font-family: 'Resolve Sans', sans-serif;
	font-size: inherit !important;
	color: #04246a !important;
	border: 0 solid #fff !important;
	text-decoration: none !important;
	padding: 0.7rem 0.5rem;
	line-height: 1;
	cursor: pointer;
}
.footer-item-link:hover {
	color: #e20101 !important;
}
.footer-icons {
	padding: 0.7rem 0.3rem;
}
.image {
	width: 100px;
	height: 100px;
}
.footer-icon {
	color: #04246a;
}
.footer-bottom {
	flex: 0.5;
	width: 100%;
	margin: 0;
	padding: 5px 0 10px 0;
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: center;
}
.footer-bottom p {
	margin: 0;
	color: rgba(4, 36, 106, 0.5);
}

.terms-condition {
    font-family: Antonio-Bold!important;
    text-decoration: underline;
}

.terms-condition:hover {
	cursor: pointer;
	color: #e20101 !important;
}
@media screen and (max-width: 1050px) {
	.footer-item-link {
		padding: 0;
		margin: 5px;
	}
}
@media screen and (max-width: 1387px) {
	.footer-middle {
		width: 100%;
		position: relative;
		margin: 0;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
}
