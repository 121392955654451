.invalid {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: whitesmoke;
}

.invalid-content {
    width: 30%;
    height: 40%;
    background-color: white;
    box-shadow: 10px 10px 5px #dddedf;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.invalid-content-row:nth-child(1) {
    flex: 0.1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.invalid-content-row:nth-child(1) p {
    color: #04246a;
    margin: 0;
    font-size: 40px;
    font-weight: 500;
}

.invalid-content-row:nth-child(2) {
    flex: 0.2;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.invalid-content-row:nth-child(2) p {
    color: #04246a;
    margin: 0;
    font-size: 20px;
    font-weight: 500;
}

.invalid-content-row:nth-child(3) {
    flex: 0.2;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.invalid-content-button {
    color: #04246a!important;
    margin: 0!important;
    text-transform: none!important;
}