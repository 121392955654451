.header-list {
	display: flex;
	align-items: center;
}

.menu-list {
	list-style-type: none;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 1rem;
}

.menu-list.close {
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0;
	align-items: flex-start;
}

.menu-list.close li a {
	text-decoration: none;
	color: #04246a;
	font-size: 16px;
	font-family: 'Antonio-Bold' !important;
}

.myOrders {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	button,
	a {
		text-decoration: none;
		color: #04246a;
		font-size: 16px;
		font-family: 'Antonio-Bold' !important;
		&:hover {
			color: #e20101 !important;
			text-decoration: underline !important;
		}
	}

	button {
		background: transparent;
		box-shadow: none;
		border-radius: 0;
		padding: 0 !important;
		margin: 8px;
		position: relative;
		min-width: 55px;

		&:hover {
			background: transparent;
			box-shadow: none;
		}
		&:not(.shoppingCartBtn) {
			&:after {
				content: '\25bc';
				position: absolute;
				top: calc((100% - 19px) / 2);
				right: 0;
				font-size: 12px;
				margin-left: 5px;
				color: gray;
			}
		}
	}
}

.menu-list.close li {
	margin: 10px;
}

.menu-list.close nav li a:hover {
	color: #e20101;
	border-bottom: 2px solid #e20101;
	border-bottom-color: #e20101;
	border-bottom-width: 2px;
}

.menu-list li a {
	text-decoration: none;
	color: #04246a;
	font-size: 16px;
	margin-left: 30px;
	font-family: 'Antonio-Bold' !important;
}

.menu-list li a:hover {
	color: #e20101;
	border-bottom: 2px solid #e20101;
	border-bottom-color: #e20101;
	border-bottom-width: 2px;
}

.header-image {
	cursor: pointer;
}
