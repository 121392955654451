.checkoutTabs {
    flex: 1;
    width: 100%;
    padding-top: 1.5%;
    padding-left: 1.5%;
    padding-right: 1.5%;
    display: flex;
}

.checkoutTabs-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.checkoutTabs-tabList {
    min-width: 320px;
    background-color: #E8E8E8;
    border-radius: 4px;
	margin-bottom: 16px;
    display: flex;
	align-items: center;
	justify-content: center;
}

.checkoutTabs-tab {
    font-family: IBM Plex Sans, sans-serif;
	color: #04246a;
	cursor: pointer;
	font-size: 0.875rem;
	font-weight: bold;
	background-color: transparent;
	width: 100%;
	padding: 12px 16px;
	margin: 0;
	border: none;
	display: flex;
    align-items: center;
	justify-content: center;
}

.checkoutTabs-tab:first-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.checkoutTabs-tab:last-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.checkoutTabs-tabPanel {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.checkoutTabs-tabPanelTop {
    flex: 0.1;
}

.checkoutTabs-tabPanelTop p {
    margin: 0;
    font-size: 25px;
    font-weight: 700;
    color: #04246a;
}

.checkoutTabs-tabPanelBottom {
    flex: 0.9;
    display: flex;
    justify-content: space-between;
}

.checkoutTabs-tabPanelBottomLeft {
    flex: 0.5;
    display: flex;
    align-items: flex-start;
}

.checkoutTabs-tabPanelBottomRight {
    flex: 0.5;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 1540px) {
    .checkoutTabs-tabPanelTop p {
        font-size: 20px;
    }
}