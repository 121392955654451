.manage-btn {
	margin-right: 10px !important;
	width: 100px !important;
	min-width: 100px !important;
	background-color: #04246a !important;
	font-family: 'Antonio-Bold' !important;
}

.manage-btn-cancel {
	width: 100px !important ;
	min-width: 100px !important;
	border: 1px solid #04246a !important;
	font-family: 'Antonio-Bold' !important;
	color: #04246a !important;
}

.filtre {
	flex: 1 !important;
	max-width: 99% !important;
	min-height: 60vh !important;
	margin-left: auto !important;
	margin-right: auto !important;
	background-color: white !important;
	padding-bottom: 20px !important;
}

.filtre-title {
	font-weight: 600 !important;
	font-size: 16px !important;
	color: #04246a !important;
	display: flex !important;
	flex-direction: column !important;
	align-items: flex-start !important;
}

.filtre-title p {
	margin: 1rem 0 !important;
	padding: 0 !important;
	font-size: 12px !important;
	font-weight: 300 !important;
	line-height: 24px !important;
	text-align: left !important;
	color: #04246a !important;
	font-family: 'Antonio-Bold' !important;
}

.filtre-title h6 {
	padding: 0 !important;
	padding-top: 5px !important;
	margin: 0 !important;
	color: red !important;
	font-size: 10px !important;
	font-weight: 400 !important;
	font-style: italic !important;
}

.filtre-title-clear {
	font-weight: 600 !important;
	margin-top: 5px !important;
	font-size: 16px !important;
	color: #04246a !important;
	display: flex !important;
	flex-direction: row !important;
	align-items: flex-start !important;
	p {
		padding: 0 !important;
		padding-top: 5px !important;
		margin: 0 !important;
		font-size: 14px !important;
		font-weight: 400 !important;
	}
}

.modal-popup-progress-bar {
	position: fixed !important;
	top: 50% !important;
	left: 50% !important;
}
.modal-popup {
	position: absolute !important;
	width: 43% !important;
	left: 50% !important;
	top: 100% !important;
}

.alert-box-wrapper {
	position: fixed !important;
	top: 150px !important;
	right: 40px !important;
}

.close-button {
	display: flex !important;
	align-items: center !important;
}
