/* Containers */
.manageBooking {
	display: flex;
	flex-direction: column;
	width: 98.4%;
	padding-left: 10px !important;
	background-color: rgb(248, 249, 250);
}

/* Title */
.manageBooking-top {
	flex: 0.05;
}

.manageBooking-top p {
	font-size: 22px;
	font-weight: 600;
	color: #04246a;
}

/* Buttons */
.manageBooking-middle {
	flex: 0.05;
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	width: 20%;
	align-self: flex-end;
}

.manageBooking-middle-button {
	display: flex;
	flex-direction: column;
}

.manageBooking-middle-button:last-child {
	margin-left: 10px;
}

.manageBooking-middle-label {
	flex: 0.4;
	color: #04246a !important;
	font-size: 15px !important;
}

.manageBooking-middle-select {
	flex: 0.6;
	width: 130px !important;
}

.manageBooking-select {
	width: 100%;
	max-width: 150px;
	/* height: 70%; */
	padding: 3px 0;
	font-size: 12px !important;
}

.manageBooking-select div {
	padding: 0;
	padding-left: 10px;
	padding-top: 4px;
	/* font-size: 12px; */
}

/* Table */
.manageBooking-bottom {
	flex: 0.9;
	display: flex;
	align-items: center;
	justify-content: center;
}

.manageBooking-table {
	flex: 1;
	display: flex !important;
	flex-direction: column;
	justify-content: space-between;
}

/* Table head */
.manageBooking-head {
	flex: 0.07;
	padding-top: 5px;
	padding-bottom: 5px;
	display: flex !important;
	flex-direction: column;
	background-color: whitesmoke;
	box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.manageBooking-row:first-child {
	flex: 0.4;
	display: flex;
	justify-content: space-between;
}

.manageBooking-row:last-child {
	flex: 0.6;
	display: flex;
	justify-content: space-between;
}

.manageBooking-cell {
	flex: 0.11;
	font-size: 16px !important;
	padding: 0 !important;
	padding-top: 10px !important;
	color: #04246a !important;
	display: flex !important;
	align-items: center;
	border-bottom: none !important;
	border-right: 1px solid lightgray;
}

.manageBooking-cell:first-child {
	flex: 0.04;
	display: flex;
	justify-content: center;
}

.manageBooking-cell:last-child {
	border-right: none;
}

.manageBooking-input {
	/* width: 80%; */
	max-width: 150px;
}

.manageBooking-input div {
	padding-left: 10px;
}

.manageBooking-input label {
	font-size: 12px;
	top: -7px;
	left: -5px;
}

.manageBooking-input div input {
	padding: 3px 0;
}

input[type='range'] {
	-webkit-appearance: none;
}

input[type='range']::-webkit-slider-runnable-track {
	width: 300px;
	height: 5px;
	background: #ddd;
	border-radius: 3px;
}

input[type='range']::-webkit-slider-thumb {
	-webkit-appearance: none;
	border: none;
	height: 12px;
	width: 12px;
	cursor: pointer;
	border-radius: 50%;
	background: #04246a;
	margin-top: -2px;
}

input[type='range']:focus::-webkit-slider-runnable-track {
	background: #ccc;
}

.range {
	background: linear-gradient(to right, silver 2px, transparent 1px) repeat-x;
	background-size: calc(100% / ((10000 - 20) / 1000) - 0.1%) 30%;
	background-position: 0 bottom;
	position: relative;
}

.range::before,
.range::after {
	font: 11px monospace;
	color: #04246a;
	content: counter(x);
	position: absolute;
	bottom: -15px;
}

.range::before {
	counter-reset: x 0;
	transform: translateX(-50%);
}

.range::after {
	counter-reset: x 10000;
	right: 0;
	transform: translateX(50%);
}

.manageBooking-datepicker {
	line-height: 1.61em;
	border-radius: 4px !important;
	border-color: rgba(0, 0, 0, 0.23);
	border-width: 1px;
	background-color: transparent;
}

.manageBooking-datepicker:focus {
	outline: none;
	cursor: pointer;
}

.manageBooking-select {
	width: 100%;
	max-width: 150px;
	/* height: 70%; */
	padding: 3px 0;
	font-size: 12px !important;
}

.manageBooking-select div {
	padding: 0;
	padding-left: 10px;
	padding-top: 4px;
	/* font-size: 12px; */
}

/* Table body */
.manageBooking-body {
	flex: 0.87;
	background-color: white;
	display: flex !important;
	flex-direction: column;
	justify-content: flex-start;
}

.manageBooking-body-row {
	flex: 0.1;
	margin: 0 !important;
	padding: 0 !important;
	margin-top: 10px !important;
	display: flex !important;
	justify-content: space-between;
	background-color: white;
	box-shadow: 5px 2px 5px #dddedf;
}

.manageBooking-body-row:first-child {
	margin-top: 0 !important;
}

.manageBooking-body-cell {
	color: #04246a !important;
	flex: 0.11;
	display: flex !important;
	padding: 5px 0 !important;
	align-items: center;
	border-bottom: none !important;
	border-right: 1px solid lightgray;
	font-size: 13px !important;
}

.manageBooking-body-cell:first-child {
	flex: 0.04;
	display: flex;
	justify-content: center;
}

.manageBooking-body-cell:last-child {
	border-right: none;
	display: flex !important;
	align-items: center !important;
	justify-content: flex-start !important;
}

.manageBooking-body-button {
	box-shadow: none !important;
	color: #04246a !important;
	border-color: #04246a !important;
	background-color: transparent !important;
}

.manageBooking-body-button:hover {
	box-shadow: none;
	color: #04246a !important;
	border-color: #04246a !important;
	background-color: transparent !important;
}

/* Table footer */
.manageBooking-footer {
	flex: 0.05;
	display: flex !important;
	justify-content: flex-end;
}

.manageBooking-pagination div p {
	margin: 0;
}

@media screen and (max-width: 1540px) {
	.manageBooking-middle {
		min-height: 5%;
	}

	.manageBooking-head {
		border-bottom: 1px solid lightgray;
	}

	/* .manageBooking-cell:nth-child(3) {
        margin-left: -7px!important;
        flex: 0.13;
    } */

	.manageBooking-body {
		flex: 0.87;
	}

	/* .manageBooking-body-cell:nth-child(3) {
        font-size: 12px!important;
        margin-left: -7px!important;
        flex: 0.13;
    } */

	.manageBooking-cell {
		font-size: 14px !important;
	}

	.manageBooking-slider {
		height: 90%;
	}

	.manageBooking-input {
		width: 100%;
		max-width: 120px;
	}

	.manageBooking-input div {
		font-size: 14px;
		padding-left: 10px;
		height: 30px;
	}

	.manageBooking-input label {
		font-size: 12px;
		font-style: italic;
		top: -8px;
	}

	.manageBooking-select {
		width: 80%;
		max-width: 150px;
		height: 70%;
		padding-top: 8px;
		padding-bottom: 8px;
	}

	.manageBooking-select div {
		padding-top: 5px;
	}

	.range::before,
	.range::after {
		font: 9px monospace;
		bottom: -10px;
	}

	.manageBooking-number {
		height: 37px;
	}

	.manageBooking-body-button {
		height: 30px;
	}

	.manageBooking-body-cell {
		/* font-size: 12.5px!important; */
	}

	.manageBooking-datepicker {
		line-height: 1.8em;
	}

	/* Buttons */
	.manageBooking-middle {
		flex: 0.05;
		width: 20%;
	}

	.manageBooking-middle-label {
		flex: 0.4;
		color: #04246a !important;
		font-size: 14px !important;
	}

	.manageBooking-middle-select {
		width: 100%;
		max-width: 150px;
		height: 50%;
		padding: 3px 0;
		font-size: 12px !important;
	}
}
