.thankyou {
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.thankyou-top {
    flex: 0.5;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.thankyou-top-image {
    background-color: white;
    width: 16%;
    height: 70%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.thankyou-top-icon {
    width: 50%!important;
    height: 50%!important;
    color: #04246a;
}

.thankyou-bottom {
    flex: 0.5;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.thankyou-bottom-title {
    flex: 0.2;
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.thankyou-bottom-title p {
    margin: 0;
    color: #04246a;
    font-size: 50px;
    font-weight: 600;
}

.thankyou-bottom-message {
    flex: 0.2;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.thankyou-checkIcon {
    color: #04246a;
    font-size: 28px;
}

.thankyou-bottom-message p {
    margin: 0;
    margin-left: 10px;
    font-size: 18px;
    font-weight: 500;
}

.thankyou-bottom-buttons {
    flex: 0.2;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.thankyou-button {
    width: 200px;
    height: 50px;
    background-color: #04246a!important;
}

.thankyou-button:hover {
    color: #04246a;
    background-color: whitesmoke!important;
}

@media screen and (max-width: 1540px) {
    .thankyou {
        height: 91vh;
    }

}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.thankyou-button.css-im881g-MuiButtonBase-root-MuiButton-root span svg
{
    color : white;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.thankyou-button.css-im881g-MuiButtonBase-root-MuiButton-root:hover span svg
{
color: #04246a;
}