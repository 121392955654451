.parallax1 {
	/* The image used */
	background-image: url('../../../../public/media/counters.jpg');

	/* Set a specific height */
	min-height: 500px;

	/* Create the parallax scrolling effect */
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	position: relative;
	display: flex;
	justify-content: center;
}

.video {
	position: absolute;
	top: 0%;
	bottom: 0%;
	width: 60%;
	display: flex;
	justify-content: center;
}

.home-page {
	height: 100vh; /* 500px */
	background-color: whitesmoke;
	/* font-size:36px; */
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

/* Page 1 Top */
.home-pageTop {
	flex: 0.5;
	background-color: lightgray;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.home-pageTop-image {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.home-pageTop-content {
	z-index: 1000;
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.home-pageTop-contentTop {
	display: flex;
	align-items: center;
	justify-content: center;
}

.home-pageTop-contentTop p {
	color: #fff;
	margin: 0;
	font-size: 40px;
	font-weight: 600;
	text-shadow: 4px 4px 4px #000;
	font-family: 'Antonio-Bold' !important;
}

.home-pageTop-contentBottom {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.home-pageTop-contentBottom p {
	color: #fff;
	margin: 0;
	font-size: 20px;
	font-weight: 500;
	/* box-shadow: 0 10px 10px rgb(0 0 0 / 0.2); */
	text-shadow: 4px 4px 4px #000;
}

/* Page 1 Bottom */
.home-pageBottom {
	flex: 0.5;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
}

/* Page 1 Bottom Top */
.Home-pageBottom-top {
	flex: 0.5;
	width: 50%;
	height: 30%;
	background-color: #fff;
	position: absolute;
	top: -10%;
	display: flex;
	justify-content: space-between;
	padding-left: 10px;
	padding-top: 5px;
	padding-right: 10px;
	padding-bottom: 10px;
	z-index: 1000;
	/* box-shadow: 0 10px 10px rgb(0 0 0 / 0.2); */
	box-shadow: 15px 10px 10px rgb(0 0 0 / 0.2);
}

.Home-pageBottom-item {
	/* flex: 0.24; */
	flex: 0.6;
	width: 20%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}

.Home-pageBottom-item button {
	font-family: 'Antonio-Bold' !important;
}

.Home-pageBottom-item:last-child {
	flex: 0.4;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Home-pageBottom-item:last-child button {
	font-size: 16px;
	margin-top: 39px;
	border-radius: 5px;
	border: 1px solid #04246a;
	color: white;
	background-color: #04246a;
	padding-top: 4px;
	padding-bottom: 4px;
	padding-left: 15px;
	padding-right: 15px;
	width: 100%;
}

.Home-pageBottom-item:last-child button:hover {
	color: #04246a;
	font-weight: 500;
	background-color: white;
}

.Home-pageBottom-itemTop {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}

.Home-pageBottom-itemTop p {
	margin-bottom: 2px;
	font-size: 18px;
	font-weight: 500;
	color: #04246a;
}

.Home-pageBottom-itemBottom {
	width: 100%;
	display: flex;
	justify-content: flex-start;
}

.Home-pageBottom-itemBottom select {
	width: 100%;
	height: 100%;
	color: #04246a;
	font-style: italic;
	border: 1px solid #04246a;
}

.Home-pageBottom-itemBottom select:focus {
	outline: none;
}

.Home-pageBottom-option {
	font-size: 12px !important;
}

/* Page 1 Bottom Down */
.Home-pageBottom-bottom {
	flex: 1;
	width: 50%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.Home-pageBottom-bottom-item {
	flex: 0.31;
	height: 70%;
	margin-top: 10%;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.Home-pageBottom-bottom-itemTop {
	/* flex: 0.65; */
	min-height: 51%;
	max-height: 51%;
	width: 100%;
	position: relative;
}

.Home-pageBottom-bottom-itemTop img {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.Home-pageBottom-bottom-itemBottom {
	/* flex: 0.35; */
	min-height: 47%;
	max-height: 47%;
	width: 100%;
	display: flex;
	align-items: flex-start;
}

.Home-pageBottom-bottom-itemBottom p {
	color: #04246a;
	font-size: 14px;
	font-weight: 500;
	padding-left: 5px;
	padding-right: 5px;
	/* font-family: 'Antonio-Bold' !important;
	letter-spacing: 0.02rem; */
}

.Home-pageBottom-bottom-itemBottom p {
	margin-left: 0;
}

@media screen and (max-width: 1540px) {
	.Home-pageBottom-item {
		flex: 0.26;
		width: 23%;
	}

	.Home-pageBottom-item button {
		font-size: 12px !important;
	}

	.Home-pageBottom-bottom-itemBottom p {
		font-size: 13.3px !important;
	}

	.Home-pageBottom-itemTop p {
		/* margin-left: 10px; */
		font-size: 15px;
	}

	.Home-pageBottom-item:last-child {
		flex: 0.18;
		width: 23%;
	}

	.Home-pageBottom-item:last-child button {
		margin-top: 36px !important;
		font-size: 16px;

		padding-top: 7px;
		padding-bottom: 7px;
		width: 100%;
	}
}

.parallax-body1 {
	height: 100vh;
	font-size: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: whitesmoke;
}

/* Page 2 */
.contact {
	flex: 0.5;
	height: 50%;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: rgb(228, 228, 228);
	box-shadow: 0 10px 10px rgb(0 0 0 / 0.2);
}

.contact-top {
	flex: 0.2;
	width: 100%;
}

.contact-top p {
	color: #04246a;
	font-size: 30px;
	font-weight: 700;
}

.contact-bottom {
	flex: 0.8;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.contact-bottom-row {
	flex: 0.24;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.contact-bottom-row:last-child {
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
}

.contact-bottom-input {
	flex: 0.45;
	height: 50%;
	border: none;
	border-radius: 5px;
	padding-left: 10px;
	padding-bottom: 10px;
	box-shadow: 0 5px 5px rgb(0 0 0 / 0.2);
	font-size: 20px;
}

.contact-bottom-input::placeholder {
	font-size: 20px;
}

.contact-bottom-input:focus {
	outline: none;
}

.contact-bottom-textarea {
	width: 100%;
	height: 100%;
	border: none;
	border-radius: 5px;
	padding-top: 10px;
	padding-left: 10px;
	font-size: 20px;
	box-shadow: 0 5px 5px rgb(0 0 0 / 0.2);
	resize: none;
}

.contact-bottom-textarea::placeholder {
	font-size: 20px;
}

@media screen and (max-width: 1540px) {
	.contact-bottom-input {
		font-size: 15px;
		padding-top: 10px;
	}

	.contact-bottom-textarea {
		font-size: 15px;
	}

	.contact-bottom-input::placeholder,
	.contact-bottom-textarea::placeholder {
		font-size: 15px;
	}
}

.contact-bottom-textarea:focus {
	outline: none;
}

.contact-bottom-btn {
	font-size: 20px;
	border-radius: 5px;
	border: 2px solid #e20101;
	color: white;
	background-color: #e20101;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 20px;
	padding-right: 20px;
	margin-top: 10px;
	box-shadow: 0 5px 5px rgb(0 0 0 / 0.2);
}

.contact-bottom-btn:hover {
	font-weight: 500;
	border: 1px solid #e20101;
	color: #e20101;
	background-color: white;
}

.parallax2 {
	/* The image used */
	background-image: url('../../../../public/media/about-background.jpg');

	/* Set a specific height */
	min-height: 500px;

	/* Create the parallax scrolling effect */
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	position: relative;
	display: flex;
	justify-content: center;
}

.counter {
	position: absolute;
	width: 750px;
	top: 30%;
	flex: 0.6;
	height: 35%;
	border-radius: 10px;
	background-color: #fff;
	display: flex;
	justify-content: space-between;
	box-shadow: 15px 10px 10px rgb(0 0 0 / 20%);
}

.counter-item {
	flex: 0.33;
	display: flex;
	align-items: center;
	justify-content: center;
	border-right: 2px dashed lightgray;
}

.counter-item:last-child {
	border: none;
}

.counter-content {
	width: 60%;
	height: 70%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.counter-content-top {
	width: 50%;
	display: flex;
	justify-content: space-between;
}

.counter-content-topLeft {
	flex: 0.5;
	display: flex;
	align-items: center;
	justify-content: center;
}

.counter-icon {
	margin-top: 2px;
	font-size: 22px;
	color: #e20101;
}

.counter-content-topRight {
	flex: 0.5;
	display: flex;
	align-items: center;
	justify-content: center;
}

.counter-content-topRight p {
	margin: 0;
	font-size: 30px;
}

.counter-content-bottom {
	display: flex;
	justify-content: center;
}

.counter-content-bottom p {
	font-family: 'Antonio-Bold' !important;
}

.counter-content p {
	color: #04246a;
	font-weight: 500;
}

@media screen and (max-width: 1200px) {
	.Home-pageBottom-top {
		width: 80%;
	}
	.Home-pageBottom-bottom {
		width: 80%;
	}
}

@media screen and (max-width: 800px) {
	.Home-pageBottom-top {
		width: 95%;
	}
	.Home-pageBottom-bottom {
		width: 95%;
	}
	.Home-pageBottom-bottom-itemTop {
		min-height: 35%;
	}
	.Home-pageBottom-bottom-item {
		height: 70%;
		justify-content: flex-start;
	}
	.counter {
		width: 450px;
	}
	.counter-content {
		width: 100%;
	}
	.Home-pageBottom-bottom-itemBottom p {
		font-size: 12.3px !important;
	}
}
