/* FilesTab */
.filesTab {
	height: 100%;
	display: flex;
	flex-direction: column;
}

/* FilesTab Payment Proof */
.filesTab-top {
	flex: 0.45;
	display: flex;
	flex-direction: column;
}

.filesTab-top-row {
	margin-bottom: 10px;
	display: flex;
	flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.filesTab-top-row p {
	font-size: 18px;
	font-weight: 600;
	color: #04246a;
	margin: 0
}

.filesTab-row-validateStatus {
	color: darkgreen;
	background-color: palegreen;
	font-weight: 500;
	padding: 4px 30px;
	border-radius: 10px;
}

.filesTab-row-reuploadStatus {
	color: darkred;
	background-color: #e20101;
	font-weight: 500;
	padding: 4px 30px;
	border-radius: 10px;
}

.filesTab-top-row-message {
	position: absolute;
	top: -70px;
	left: 0;
	width: 20%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.filesTab-top-row-icon {
	width: 90px !important;
	height: 90px !important;
	color: lightgray !important;
}

.filesTab-top-row-message p {
	margin: 0;
	font-weight: 600;
	color: lightgray;
	text-align: center;
}

/* FilesTab Custom Clearance */
.filesTab-bottom {
	flex: 0.45;
	display: flex;
	flex-direction: column;
}

/* FilesTab Custom Clearance title */
.filesTab-bottom-row:nth-child(1) {
	flex: 0.3;
	display: flex;
	align-items: center;
}

.filesTab-bottom-row:nth-child(1) p {
	font-size: 18px;
	font-weight: 600;
	color: #04246a;
}

/* FilesTab Custom Clearance Receipt */
.filesTab-bottom-row:nth-child(2) {
	flex: 0.3;
	display: flex;
	align-items: flex-start;
}

.filesTab-bottom-row:nth-child(2) a {
	font-size: 18px;
	color: #04246a;
}

/* FilesTab Custom Clearance Buttons - Status */
.filesTab-bottom-row:nth-child(3) {
	flex: 0.3;
	display: flex;
	align-items: flex-start;
	position: relative;
}

.filesTab-bottom-validateBtn {
	color: green !important;
	border: 2px solid green !important;
}

.filesTab-bottom-reuploadBtn {
	color: red !important;
	border: 2px solid red !important;
}

.filesTab-bottom-row-message {
	position: absolute;
	top: -40px;
	left: 0;
	width: 20%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.filesTab-bottom-row-icon {
	width: 90px !important;
	height: 90px !important;
	color: lightgray !important;
}

.filesTab-bottom-row-message p {
	margin: 0;
	font-weight: 600;
	color: lightgray;
	text-align: center;
}
