/* Agent */
.agent {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: rgb(248, 249, 250);
}

/* Agent top */
.agent-top {
	flex: 0.48;
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}

.agent-topLeft {
	width: 46%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.agent-topLeft-row {
	flex: 0.48;
	display: flex;
	justify-content: space-between;
	max-height: 200px;
	min-height: 200px;
	height: 200px;
	margin-bottom: 20px;
}

.agent-topLeft-item {
	flex: 0.47;
	/* background-color: aliceblue; */
	border-radius: 35px;
	border-right: 4px solid #04246a;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: white;
	box-shadow: 10px 10px 5px #dddedf;
}

.agent-topLeft-itemRow:nth-child(1) {
	flex: 0.2;
}

.agent-topLeft-itemRow.title p {
	font-family: 'Antonio-Bold' !important;
}

.agent-topLeft-itemRow:nth-child(1) p {
	font-size: 20px;
	font-weight: 600;
	margin: 0;
}

.agent-topLeft-itemRow:nth-child(2) {
	flex: 0.5;
	border-bottom: 2px solid lightgray;
	display: flex;
	justify-content: space-between;
}

.agent-topLeft-itemRow-left {
	flex: 0.5;
	display: flex;
	align-items: center;
}

.agent-topLeft-icon {
	width: 70% !important;
	height: 70% !important;
	color: #04246a;
}

.agent-topLeft-itemRow-right {
	flex: 0.5;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.agent-topLeft-itemRow-right p {
	font-size: 35px;
	font-weight: 600;
	font-family: 'Antonio-Bold' !important;
}

.agent-topLeft-itemRow:nth-child(3) {
	flex: 0.2;
	display: flex;
	align-items: center;
	justify-content: center;
}

.agent-topLeft-itemRow:nth-child(3) p {
	font-size: 16px;
	font-weight: 600;
	margin: 0;
}

.agent-topLeft-item:last-child {
	border-right-color: #bb0101;
}

.table-orders-button {
	margin-left: auto !important;
	margin-right: 20px !important;
	font-family: 'Roboto-Medium' !important;
	font-size: 13px !important;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.table-orders-button:hover {
	cursor: pointer;
}

.table-orders-button p {
	margin-bottom: 0 !important;
}

.agent-topRight {
	height: 425px;
	width: 46.5%;
	display: flex;
	justify-content: flex-end;
	border-right: 4px solid #bb0101;
	border-radius: 35px;
	box-shadow: 10px 10px 5px #dddedf;
}

.agent-topRight-orders {
	flex: 1;
	border-radius: 5px;
	border-right: 4px solid #bb0101;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: white;
	box-shadow: 10px 10px 5px #dddedf;
}

.agent-topRight-ordersRow:nth-child(1) {
	flex: 0.1;
}

.agent-topRight-ordersRow:nth-child(1) p {
	margin: 0;
	color: #04246a;
	font-size: 20px;
	font-weight: 600;
}

.agent-topRight-ordersRow:nth-child(2) {
	background-color: #f4f4f4;
	flex: 0.15;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.agent-topRight-ordersRow:nth-child(3) {
	flex: 0.65;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.agent-topRight-ordersRow-item {
	margin-top: 10px;
	width: 100%;
	flex: 0.2;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.agent-topRight-ordersRow:nth-child(2) p,
.agent-topRight-ordersRow-item p {
	margin: 0;
	color: #04246a;
	font-weight: 600;
	font-size: 16px;
}

.agent-topRight-ordersRow:nth-child(2) p:nth-child(1),
.agent-topRight-ordersRow-item p:nth-child(1) {
	flex: 0.2;
}

.agent-topRight-ordersRow:nth-child(2) p:nth-child(2),
.agent-topRight-ordersRow-item p:nth-child(2) {
	flex: 0.2;
}

.agent-topRight-ordersRow:nth-child(2) p:nth-child(3),
.agent-topRight-ordersRow-item p:nth-child(3) {
	flex: 0.2;
}

.agent-topRight-ordersRow:nth-child(2) p:nth-child(4),
.agent-topRight-ordersRow-item p:nth-child(4) {
	flex: 0.3;
}

.agent-topRight-ordersRow:nth-child(4) {
	flex: 0.1;
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
}

.agent-topRight-ordersRow:nth-child(4) p {
	margin: 0;
	font-size: 16px;
	font-weight: 600;
	cursor: pointer;
	color: #04246a;
}

/* Agent bottom */
.agent-bottom {
	flex: 0.48;
	display: flex;
	justify-content: space-between;
	margin-bottom: 50px;
}

.agent-bottomLeft,
.agent-bottomRight {
	width: 46%;
	flex: 0.46;
	display: flex;
	align-items: center;
	justify-content: center;
}

.agent-bottomLeft-type,
.agent-bottomRight-type {
	flex: 1;
	height: 400px;
	border-right: 4px solid #04246a;
	background-color: white;
	box-shadow: 10px 10px 5px #dddedf;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	border-radius: 35px;
}

.agent-bottomLeft-typeTitle,
.agent-bottomRight-typeTitle {
	flex: 0.15;
	width: 100%;
	height: 265px !important;
	max-height: 265px !important;
	padding-left: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

.agent-bottomLeft-typeTitle p,
.agent-bottomRight-typeTitle p {
	font-size: 20px;
	margin: 0;
	font-weight: 600;
	font-family: 'Antonio-Bold' !important;
}

.agent-bottomLeft-typeContent,
.agent-bottomRight-typeContent {
	flex: 0.85;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* Start chart */
.donut {
	width: 40%;
	height: 80%;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.hole {
	width: 70%;
	height: 70%;
	border-radius: 50%;
	background: #fff;
}

.segment1 {
	position: absolute;
	top: 0;
	right: 0;
}

.segment2 {
	position: absolute;
	bottom: 0;
	right: 0;
}

.segment3 {
	position: absolute;
	top: 0;
	left: 0;
}
/*End chart */

@media screen and (max-width: 1540px) {
	.agent-topLeft-itemRow:nth-child(1) p {
		font-size: 16px;
	}

	.agent-topLeft-itemRow:nth-child(3) p {
		font-size: 14px;
	}

	.agent-topLeft-icon {
		width: 60% !important;
		height: 60% !important;
	}

	.agent-topLeft-itemRow-right p {
		font-size: 30px;
		margin-top: 10px;
	}

	.agent-topLeft-shareIcon {
		width: 20px !important;
		height: 20px !important;
	}
}
text {
	font-size: 6px !important;
}
.typeContent svg {
	width: 60% !important;
}

.doughnut-chart {
	margin-top: 50px !important;
}

@media screen and (max-width: 1150px) {
	.agent-top {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.agent-topLeft {
		width: 80%;
	}

	.agent-topLeft-row {
		min-height: 240px;
	}

	.agent-topRight {
		width: 81%;
	}
	.agent-bottom {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.agent-bottomLeft,
	.agent-bottomRight {
		width: 81%;
	}

	.agent-bottomLeft {
		margin-bottom: 20px;
	}
}
