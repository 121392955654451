/* Manage order */
.manageOrder {
    height: auto;
    display: flex;
    flex-direction: column;
    width: 98.4%;
    padding-left: 10px!important;
    background-color: rgb(248, 249, 250);
}

.manageOrder-top p {
    font-size: 22px;
    font-weight: 600;
    color: #04246a;
}

.manageOrder-tab {
    color: #04246a!important;
}