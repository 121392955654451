.shoppingCart-content {
    min-height: 30vh;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 10px;
}

.shoppingCart-title {
    flex: 0.1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid lightgray;
}

.shoppingCart-title p {
    flex: 1;
    margin: 0;
    color: #314b85;
    font-weight: 500;
}

.shoppingCart-title button {
    min-width: 0;
    padding: 0;
    color: lightgray;
    cursor: pointer;
    font-size: 500;
    border: 1px solid lightgray;
    border-radius: 50%;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;
    align-items: center;
    width: 20px;
    height: 20px;
}

.shoppingCart-items {
    flex: 0.55;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.shoppingCart-item {
    flex: 0.2;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    border-bottom: 1px solid lightgray;
}

.shoppingCart-itemLeft {
    flex: 0.15;
    display: flex;
    align-items: center;
}

.shoppingCart-itemLeft img {
    width: 25px;
    height: 25px;
}

.shoppingCart-itemMiddle {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.shoppingCart-itemMiddle-top {
    flex: 0.5;
    width: 100%;
    font-size: 14px;
    color: #314b85;
    font-weight: 500;

    text-overflow: ellipsis;
    overflow: hidden; 
    width: 170px; 
    white-space: nowrap;
}

.shoppingCart-itemMiddle-bottom {
    width: 100%;
    flex: 0.5;
    color: gray;
    font-size: 14px;
}

.shoppingCart-itemRight {
    flex: 0.36;
    color: #314b85;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.shoppingCart-summary {
    flex: 0.2;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.shoppingCart-summaryContent {
    flex: 0.5;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}

.shoppingCart-subtotal {
    flex: 0.5;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.shoppingCart-subtotal p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #314b85;
}

.shoppingCart-total {
    flex: 0.5;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.shoppingCart-total p {
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    color: #314b85;
}

.shoppingCart-checkout {
    flex: 0.1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.shoppingCart-button {
    flex: 1;
    background-color: #314b85!important;
}

@media screen and (max-width: 1540px) {
    .shoppingCart-content {
        min-height: 40vh;
        width: 270px;
    }

    .shoppingCart-itemMiddle-top {
        font-size: 12px;
        width: 140px; 
    }
    
    .shoppingCart-itemMiddle-bottom {
        font-size: 12px;
    }
    
    .shoppingCart-itemRight {
        font-size: 12px;
    }

    .shoppingCart-subtotal p,
    .shoppingCart-total p {
        font-size: 12px;
    }
}