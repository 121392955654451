/* Order */
.order {
    width: 60%;
    height: 97%;
    max-height: 97%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 5px 5px 5px #dddedf;
    background-color: #E8E8E8;
    overflow: auto;
}

.order-row {
    flex: 0.2;
}

/* Order Summary */
.order-row:first-child {
    flex: 0.08;
    border-bottom: 2px solid lightgray;
}

.order-row:first-child p {
    margin: 0;
    font-size: 25px;
    font-weight: 700;
    color: #04246a;
}

/* Order Summary Rows */
.order-row:nth-child(2) {
    flex: 0.6;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
}

/* Order Summary Row */
.order-row-item {
    flex: 0.2;
    min-height: 10%;
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
}

.order-row-item:first-child {
    margin-top: 0;
}

.order-row-item .order-rowLeft {
    flex: 0.6;
    height: 100%;
    display: block;
    align-items: center;
    justify-content: flex-start;
}

.order-row-item .order-rowLeft p {
    margin: 0;
    font-size: 18px;
    color: #04246a;
}

.order-row-item .order-rowMiddle {
    flex: 0.2;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.order-row-item .order-rowMiddle p {
    margin: 0;
    font-size: 18px;
    color: #04246a;
}

.order-row-item .order-rowRight {
    flex: 0.2;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.order-row-item .order-rowRight p {
    margin: 0;
    font-size: 18px;
    color: #04246a;
}

/* VAT */
.order-row:nth-child(3) {
    flex: 0.08;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid lightgray;
}

.order-row:nth-child(3) .order-rowLeft {
    flex: 0.6;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.order-row:nth-child(3) .order-rowLeft p {
    margin: 0;
    font-size: 20px;
    font-weight: 700;
    color: #04246a;
}

.order-row:nth-child(3) .order-rowMiddle {
    flex: 0.2;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.order-row:nth-child(3) .order-rowMiddle p {
    margin: 0;
    font-size: 18px;
    color: #04246a;
}

.order-row:nth-child(3) .order-rowRight {
    flex: 0.2;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.order-row:nth-child(3) .order-rowRight p {
    margin: 0;
    font-size: 18px;
    color: #04246a;
}

/* Total Cost */
.order-row:nth-child(4) {
    flex: 0.08;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.order-row:nth-child(4) .order-rowLeft {
    flex: 0.6;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.order-row:nth-child(4) .order-rowLeft p {
    margin: 0;
    font-size: 20px;
    font-weight: 700;
    color: #04246a;
}

.order-row:nth-child(4) .order-rowRight {
    flex: 0.2;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.order-row:nth-child(4) .order-rowRight p {
    margin: 0;
    font-size: 18px;
    color: #04246a;
}

/* Checkout */
.order-row:nth-child(5) {
    flex: 0.1;
}

.order-row:nth-child(5) button {
    color: white;
    background-color: #04246a;
    border: 1px solid #04246a;
    width: 100%;
    height: 100%;
    font-size: 18px;
}

.order-row-button:hover {
    color: #04246a!important;
    background-color: whitesmoke!important;
}

@media screen and (max-width: 1540px) {
    .order {
        max-height: 80%;
    }

    .order-row:first-child p {
        font-size: 18px;
    }

    .order-row:nth-child(2) {
        max-height: 60%;
    }

    .order-row-item {
        flex: 0.3;
    }

    .order-row-item .order-rowLeft p,
    .order-row-item .order-rowMiddle p,
    .order-row-item .order-rowRight p,
    .order-row:nth-child(3) .order-rowLeft p,
    .order-row:nth-child(3) .order-rowMiddle p,
    .order-row:nth-child(3) .order-rowRight p,
    .order-row:nth-child(4) .order-rowLeft p,
    .order-row:nth-child(4) .order-rowMiddle p,
    .order-row:nth-child(4) .order-rowRight p,
    .order-row:nth-child(5) button {
        font-size: 15px;
    }
}