/* View containers */
.viewContainers {
    height: 100%;
    display: flex;
    flex-direction: column;
}

/* Button */
.viewContainers-top {
    flex: 0.1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

/* Table */
.viewContainers-bottom {
    flex: 0.9;
    display: flex;
}

.viewContainers-table {
    flex: 1;
    display: flex!important;
    flex-direction: column;
    justify-content: space-between;
}

/* Table head */
.viewContainers-head {
    flex: 0.07;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex!important;
    flex-direction: column;
    background-color: whitesmoke;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.viewContainers-row {
    flex: 1;
    padding-left: 10px;
    display: flex!important;
    justify-content: space-between;
}

.viewContainers-cell {
    flex: 0.14;
    font-size: 16px!important;
    padding: 0!important;
    padding-top: 10px!important;
    color: #04246a!important;
    display: flex!important;
    align-items: center;
    border-bottom: none!important;
    border-right: 1px solid lightgray;
}

.viewContainers-cell:last-child {
    border-right: none;
}

/* Table body */
.viewContainers-body {
    flex: 0.925;
    display: flex!important;
    flex-direction: column;
    justify-content: flex-start;
}

.viewContainers-body-row {
    flex: 0.1;
    margin: 0!important;
    padding: 0!important;
    padding-left: 10px!important;
    margin-top: 10px!important;
    display: flex!important;
    justify-content: space-between;
    background-color: white;
    box-shadow: 5px 2px 5px #dddedf;
}

.viewContainers-body-row:first-child {
    margin-top: 0!important;
}

.viewContainers-body-cell {
    color: #04246a!important;
    flex: 0.14;
    display: flex!important;
    padding: 5px 0!important;
    align-items: center;
    border-bottom: none!important;
    border-right: 1px solid lightgray;
    font-size: 13px!important;
}

.viewContainers-body-cell:last-child {
    border-right: none;
}