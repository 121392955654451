/* Admin Depots */
.adminDepots {
    height: 92vh;
    display: flex;
    flex-direction: column;
    width: 98.4%;
    padding-left: 10px!important;
    background-color: rgb(248, 249, 250);
}

/* Title */
.adminDepots-title {
    flex: 0.05;
    font-size: 20px;
    font-weight: 500;
    color: #04246a;
}

/* Table */
.adminDepots-table {
    flex: 0.85;
    width: 100%!important;
    display: flex!important;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: hidden;
}

/* Table head */
.adminDepots-head {
    flex: 0.07;
    width: 100%;
    display: flex!important;
}

.adminDepots-head-row {
    flex: 1;
    padding-bottom: 0;
    display: flex!important;
    justify-content: space-between;
    background-color: whitesmoke;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.adminDepots-head-row-item {
    flex: 0.33;
    color: #04246a!important;
    font-weight: 600!important;
    font-size: 15px!important;
    border-bottom: none!important;
    border-right: 1px solid lightgray;
}

/* Table body */
.adminDepots-body {
    flex: 0.93;
    width: 100%;
    display: flex!important;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.adminDepots-body-row {
    flex: 0.08;
    width: 100%;
    display: flex!important;
    justify-content: space-between;
    margin-top: 10px!important;
    background-color: white;
    box-shadow: 5px 2px 5px #dddedf;
}

.adminDepots-body-row:first-child {
    margin-top: 0px!important;
}

.adminDepots-body-row-item {
    flex: 0.33;
    border-bottom: none!important;
    color: #04246a!important;
    display: flex;
    align-items: center;
    border-right: 1px solid lightgray;
}

.adminDepots-body-row-item:nth-child(3) img {
    width: 20px!important;
    height: 20px!important;
}

/* Grid */
.adminDepots-grid {
    flex: 0.1;
}

.adminDepots-grid-item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

@media screen and (max-width: 1540px) {
    .adminDepots {
        height: 89vh;
    }
}