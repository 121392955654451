/* Containers */
.agentConfirmation {
	display: flex;
	flex-direction: column;
	background-color: rgb(248, 249, 250);
}

/* Title */
.agentConfirmation-top {
	flex: 0.05;
	margin-bottom: 15px;
}

.agentConfirmation-top p {
	font-size: 22px;
	font-weight: 600;
	color: #04246a;
	margin-bottom: 0 !important;
	font-family: 'Antonio-Bold' !important;
}

/* Buttons */
.agentConfirmation-middle {
	flex: 0.05;
	margin-bottom: 5px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.agentConfirmation-table {
	flex: 1;
	display: flex !important;
	flex-direction: column;
	justify-content: space-between;
}

.agentConfirmation-bottom {
	display: flex;
	justify-content: center;
}

/* Table head */
.agentConfirmation-head {
	flex: 0.07;
	padding-top: 5px;
	padding-bottom: 5px;
	display: flex !important;
	flex-direction: column;
	background-color: whitesmoke;
	box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.agentConfirmation-row:first-child {
	flex: 0.4;
	display: flex;
	justify-content: space-between;
}

.agentConfirmation-row:last-child {
	flex: 0.6;
	display: flex;
	justify-content: space-between;
}

.agentConfirmation-cell {
	flex: 0.11;
	font-size: 16px !important;
	padding: 0 !important;
	padding-top: 10px !important;
	color: #04246a !important;
	display: flex !important;
	align-items: center;
	border-bottom: none !important;
	border-right: 1px solid lightgray;
}

.agentConfirmation-cell:first-child {
	flex: 0.05;
	display: flex;
	justify-content: center;
}

.agentConfirmation-cell:last-child {
	border-right: none;
}

.agentConfirmation-input {
	/* width: 80%; */
	max-width: 150px;
}

.agentConfirmation-input div {
	padding-left: 10px;
}

.agentConfirmation-input label {
	font-size: 12px;
	top: -7px;
	left: -5px;
}

.agentConfirmation-input div input {
	padding: 3px 0;
}

input[type='range'] {
	-webkit-appearance: none;
}

input[type='range']::-webkit-slider-runnable-track {
	width: 300px;
	height: 5px;
	background: #ddd;
	border-radius: 3px;
}

input[type='range']::-webkit-slider-thumb {
	-webkit-appearance: none;
	border: none;
	height: 12px;
	width: 12px;
	cursor: pointer;
	border-radius: 50%;
	background: #04246a;
	margin-top: -2px;
}

input[type='range']:focus::-webkit-slider-runnable-track {
	background: #ccc;
}

.range {
	background: linear-gradient(to right, silver 2px, transparent 1px) repeat-x;
	background-size: calc(100% / ((10000 - 20) / 1000) - 0.1%) 30%;
	background-position: 0 bottom;
	position: relative;
}

.range::before,
.range::after {
	font: 11px monospace;
	color: #04246a;
	content: counter(x);
	position: absolute;
	bottom: -15px;
}

.range::before {
	counter-reset: x 0;
	transform: translateX(-50%);
}

.range::after {
	counter-reset: x 10000;
	right: 0;
	transform: translateX(50%);
}

.agentConfirmation-number {
	border-radius: 4px !important;
	border-color: rgba(0, 0, 0, 0.23);
	border-width: 1px;
}

.agentConfirmation-number:focus {
	outline: none;
}

.agentConfirmation-select {
	width: 100%;
	max-width: 150px;
	/* height: 70%; */
	padding: 3px 0;
	font-size: 12px !important;
}

.agentConfirmation-select div {
	padding: 0;
	padding-left: 10px;
	padding-top: 4px;
	/* font-size: 12px; */
}

/* Table body */
.agentConfirmation-body {
	flex: 0.87;
	background-color: white;
	display: flex !important;
	flex-direction: column;
	justify-content: flex-start;
}

.agentConfirmation-body-row {
	flex: 0.1;
	margin: 0 !important;
	padding: 0 !important;
	margin-top: 10px !important;
	display: flex !important;
	justify-content: space-between;
	background-color: white;
	box-shadow: 5px 2px 5px #dddedf;
}

.agentConfirmation-body-row:first-child {
	margin-top: 0 !important;
}

.agentConfirmation-body-cell {
	color: #04246a !important;
	flex: 0.11;
	display: flex !important;
	padding: 5px 0 !important;
	align-items: center;
	border-bottom: none !important;
	border-right: 1px solid lightgray;
}

.agentConfirmation-body-cell:first-child {
	flex: 0.05;
	display: flex;
	justify-content: center;
}

.agentConfirmation-body-cell:last-child {
	border-right: none;
	display: flex !important;
	align-items: center !important;
	justify-content: flex-start !important;
}

.agentConfirmation-body-button {
	box-shadow: none !important;
	color: #04246a !important;
	background-color: transparent !important;
}

.agentConfirmation-body-button:hover {
	box-shadow: none;
	color: #04246a !important;
	background-color: transparent !important;
}

/* Table footer */
.agentConfirmation-footer {
	flex: 0.05;
	display: flex !important;
	justify-content: flex-end;
}

.agentConfirmation-pagination div p {
	margin: 0;
}
.MuiTableRow-root:hover {
    cursor: default !important;
}
@media screen and (max-width: 1540px) {
	.agentConfirmation-middle {
		min-height: 5%;
	}

	.agentConfirmation-head {
		border-bottom: 1px solid lightgray;
	}

	.agentConfirmation-body {
		flex: 0.87;
	}

	.agentConfirmation-cell {
		font-size: 14px !important;
	}

	.agentConfirmation-input {
		width: 100%;
		max-width: 120px;
	}

	.agentConfirmation-input div {
		font-size: 14px;
		padding-left: 10px;
		height: 30px;
	}

	.agentConfirmation-input label {
		font-size: 12px;
		font-style: italic;
		top: -8px;
	}

	.agentConfirmation-select {
		width: 80%;
		max-width: 150px;
		height: 70%;
		padding-top: 8px;
		padding-bottom: 8px;
	}

	.agentConfirmation-select div {
		padding-top: 5px;
	}

	.range::before,
	.range::after {
		font: 9px monospace;
		bottom: -10px;
	}

	.agentConfirmation-number {
		height: 37px;
	}
}
