/* List containers */
.listContainers {
    padding-top: 15px;
    display: flex;
    flex-direction: column;
}

/* List containers top */
.listContainers-top {
    flex: 0.1;
    display: flex;
    align-items: center;
}

.listContainers-top p {
    font-size: 16px;
    font-weight: 600;
    color: #04246a;
}

/* List container header */
.listContainer-listHeader {
    display: flex!important;
    justify-content: space-between;
    padding: 20px 20px!important;
    margin-top: 7px!important;
    margin-bottom: 7px!important;
    background-color: whitesmoke;
    box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
}

.listContainer-listHeader-item {
    color: #04246a;
    flex: 0.2!important;
    display: flex!important;
    align-items: center;
}

.listContainer-listHeader-item span span:first-child {
    font-weight: 600;
}

.listContainer-listHeader-item span {
    font-size: 15px!important;
}

/* List container content */
.listContainer-listContent {
    background-color: whitesmoke;
    margin-top: 10px!important;
    margin-bottom: 10px!important;
}

.listContainer-cell {
    color: #04246a!important;
    font-weight: 600!important;
}

.listContainer-body-cell {
    color: #04246a!important;
}

/* List containers bottom */
.listContainers-bottom {
    /* flex: 0.1; */
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.listContainers-bottom-button {
    width: 12%;
    background-color: #04246a!important;
}