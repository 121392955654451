/*
 * Font Family Generation
 * -----------------------
 * reference: https://levelup.gitconnected.com/how-to-add-a-new-font-into-your-ionic-app-b7e0871ff5f4
*/

@font-face {
	font-family: 'Antonio-Bold';
	src: url('./fonts/antonio/Antonio-Bold.ttf');
}

@font-face {
	font-family: 'Antonio-Regular';
	src: url('./fonts/antonio/Antonio-Regular.ttf');
}

@font-face {
	font-family: 'Antonio-Light';
	src: url('./fonts/antonio/Antonio-Light.ttf');
}

@font-face {
	font-family: 'Roboto-Bold';
	src: url('./fonts/roboto/Roboto-Bold.ttf');
}

@font-face {
	font-family: 'Roboto-Medium';
	src: url('./fonts/roboto/Roboto-Medium.ttf');
}

@font-face {
	font-family: 'Roboto-Light';
	src: url('./fonts/roboto/Roboto-Light.ttf');
}


