.menu {
	height: 100%;
	color: white !important;
	padding-top: 0px;
	background-color: #314b85 !important;
}

.links {
	color: #fff !important;
}

.menu ul {
	list-style: none;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	padding-left: 0;
	height: 15%;
}
.menu li {
	flex: 0.33;
	width: 100%;
	cursor: pointer;
	border-bottom: 1px solid #fff;
	display: flex;
	align-items: center;
}

.menu ul li .links {
	text-decoration: none;
	color: white !important;
	font-size: 15px;
	font-weight: 500;
	padding-left: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
	width: 100%;
}

.menu ul li.active {
	background-color: #516daa !important;
}

.menu ul li.active a {
	color: white;
}

.menu ul li.inactive {
	background-color: #314b85;
}

.menu ul li.inactive a {
	color: white;
}
