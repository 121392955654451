.confirmation {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.confirmation-order {
    flex: 0.35;
    height: 95%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 5px 5px 5px #dddedf;
    background-color: #E8E8E8;
}

.confirmation-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
}

.confirmation-button:first-child {
    color: #04246a;
    border: 1px solid #04246a;
}

.confirmation-button:first-child:hover {
    color: whitesmoke;
    background-color: #04246a;
}

.confirmation-button:last-child {
    background-color: #04246a;
}

.confirmation-button:last-child:hover {
    color: #04246a;
    background-color: whitesmoke;
}